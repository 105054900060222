<template>
  <!-- 新增添加角色页面 -->
  <div>
    <div class="jueSe">
      <p>修改角色</p>
    </div>
    <el-form ref="ruleForm" :model="ruleForm" label-width="120px" :rules="rules">
      <el-form-item label="角色名" prop="roleName">
        <el-input disabled v-model="ruleForm.roleName" placeholder="请输入角色名" style="width: 600px"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="roleComments">
        <el-input v-model="ruleForm.roleComments" placeholder="备注" style="width: 600px"></el-input>
      </el-form-item>
      <el-form-item label="角色是否可用">
        <el-radio-group v-model="ruleForm.del">
          <el-radio :label="0">可用</el-radio>
          <el-radio :label="1">不可用</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="textAnnotation">
        <p>拥有权限<span>(注：选择子菜单，必须把父级菜单、祖级菜单勾选，取消菜单同理)</span></p>
      </div>
      <el-form-item label="">
        <!-- <el-tree
        :data="routerData"
        show-checkbox
        default-expand-all
        node-key="id"
        ref="tree"
        highlight-current
        :props="{
          label: 'title',
          children: 'children'
        }"
      >
      </el-tree> -->
        <el-tree
          :data="data"
          show-checkbox
          node-key="id"
          :default-expand-all="true"
          :default-checked-keys="defaults"
          ref="tree"
          :props="defaultProps"
          @check="handleNodeClick"
        >
        </el-tree>
      </el-form-item>
      <el-form-item label="">
        <div>
          <el-button type="primary" @click="goAddAdmin">提交</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from '@/axios'
export default {
  data() {
    return {
      // 多选框的名称
      data: [],
      defaults: [], // 默认值
      defaultProps: {
        children: 'children',
        label: 'acitonName'
      },
      ruleForm: {
        roleName: '', // 用户名
        roleComments: '', //备注
        del: 0,
        actions: []
      },
      //   验证规则
      rules: {
        roleName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        roleComments: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      uid: ''
    }
  },
  created() {
    this.uid = this.$route.query.id
    this.ruleForm.createBy = JSON.parse(window.sessionStorage.getItem('admin')).adminId
    this.getPermission()
    this.getData()
  },
  methods: {
    // 提交
    goAddAdmin() {
      console.log(this.actions)
      let arr = []
      this.actions.forEach(item => {
        arr.push(item.id)
      })
      this.ruleForm.actions = [...new Set(arr)]
      // console.log(this.ruleForm)
      this.axios
        .put(
          `/admin/roleAction/updateRoleAction?action=${this.ruleForm.actions}&adminId=${
            JSON.parse(window.sessionStorage.getItem('admin')).adminId
          }&roleId=${this.uid}`,
          {}
        )
        .then(res => {
          if (res.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$router.go(-1)
          }
        })
        .catch(err => {
          this.$message({
            message: '保存失败',
            type: 'error'
          })
        })
    },
    // 获取所有列表
    getData() {
      this.axios.get('/admin/action/selectActionList2', {}).then(res => {
        console.log(res, '999999999999')
        this.data = res.data.data
      })
    },
    handleNodeClick(data, ss, indeterminate) {
      this.actions = ss.checkedNodes
    },
    //   返回上一页面
    getBack() {
      this.$router.go(-1)
    },
    // 获取角色权限信息
    getPermission() {
      this.axios
        .get('/admin/roleAction/selectRoleActionList', {
          roleId: this.$route.query.id
        })
        .then(res => {
          console.log(res, '66666666')
          this.ruleForm = res.data.data
          this.actions = this.ruleForm.actionList
          if (this.ruleForm.actionList.length) {
            this.ruleForm.actionList.forEach(item => {
              this.defaults.push(item.id)
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.textAnnotation {
  margin-left: 40px;
  p {
    margin: 0px;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    span {
      font-size: 12px;
      color: #ff0000;
    }
  }
}
.jueSe {
  p {
    font-weight: bold;
    color: #409eff;
  }
}
</style>
